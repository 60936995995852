import { MainLayout } from '@/layouts';
import { isNil } from '@/lib/utils/values';
import { DashboardViewSkeleton, useDashboardPageData } from '@/views';
import { DashboardView } from '@/views/dashboard/DashboardView';
import { ErrorMessage } from '@/views/shared';
import { useStorage } from '@/hooks';

function DashboardContent() {
    const { getItem } = useStorage();
    const tier =
        isNil(getItem('dealTier')) || ['RENT', 'ALL'].includes(getItem('dealTier')) ? 'PAID' : getItem('dealTier');

    const { isLoading, isError, isSuccess, data: DashboardData } = useDashboardPageData({ ...(tier && { tier }) });

    if (isLoading) return <DashboardViewSkeleton />;
    if (isError)
        return (
            <>
                <h1 className="font-semibold text-2xl">Panel principal</h1>
                <ErrorMessage msg="Error al intentar obtener las estadísticas" />
            </>
        );
    if (isSuccess) return <DashboardView data={DashboardData} />;

    return null;
}

export default function Dashboard() {
    return (
        <MainLayout className="max-w-screen p-6">
            <DashboardContent />
        </MainLayout>
    );
}
